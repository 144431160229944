@use './breakpoints.scss';

:root{
    --canvas-width: 100%; // with respect to its container, has to be split between controls and rendering screen
    --controls-width: 50%;
    --controls-overlap: 50%; // how much do controls overlap the rendering screen


    --canvas-width-hidden: 100%;
    --controls-width-hidden: 0%;
    --controls-overlap-hidden: 10%;

    --translateX-controls: 0%;
    --translateX-controls-hidden: calc(100% - 2rem);
}

@media (max-width: breakpoints.$mobile-breakpoint) {
    :root {
        --canvas-width: 100%; // with respect to its container, has to be split between controls and rendering screen
        --controls-width: 100%;
        --controls-overlap: 100%; // how much do controls overlap the rendering screen


        --canvas-width-hidden: 100%;
        --controls-width-hidden: 0%;
        --controls-overlap-hidden: 10%;

        --translateX-controls: 0%;
        --translateX-controls-hidden: calc(100% - 2rem);
    }
}



// styles to override default Drei styles
// styles is set on a div above canvas
//      parent div
//          div with canvas
//          div with controls

.canvas-render-screen {
    //z-index: -1; // disables canvas controls
    //pointer-events: none  !important;
    //user-select: all !important;
}
.canvas-render-screen > div:first-child {
    width: var(--canvas-width) !important;
    //width: 50% !important;
    //position: relative;
    //z-index: -1;
}

// set on div with controls
.canvas-controls {
    width: var(--controls-width) !important;
    left: calc(var(--canvas-width) - var(--controls-overlap)) !important;
    transform: translateX(var(--translateX-controls)) !important;
    display: block !important;
}

.canvas-controls > div:first-child {
    display: flex;
    height: 100%;
    width: 100%;
}

.canvas-error-message {
    transform: none !important; 
    display: block !important;
}

.canvas-date {
    transform: none !important; 
    display: block !important;
    margin-top: 1rem;
    margin-left: 1rem;
}