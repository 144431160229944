@use '../assets/breakpoints';


.hamburgerDiv {
    display: none;
    width: 2rem;
    height: 2rem;
    margin: calc((var(--navbar-height) - 2rem) /2 );
}

.linkList {
    display: flex;
}

.link {
    display: block;
    padding: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: var(--white);
    font-size: 1.3rem;
    padding: 1rem 2rem 1rem 2rem;
}

.link:hover {
    background-color: var(--red-1);
    color: var(--white)

    //border-style: solid;
    //border-color: var(--brown);
    //border-width: 0 0 0.5rem 0;
}

@media (max-width: breakpoints.$mobile-breakpoint) {
    .hamburgerDiv {
        display: block;
    }

    .linkList {
        display: block;
        position: absolute;
        right: 0;
        z-index: 99999999;
    }

    .link {
        display: none;
        background-color: var(--primary-1);
    }

    .display {
        display: block;
    }

}