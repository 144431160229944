.about {
    padding: 1rem;

    p {
        margin: 1rem 0 1rem 0;
    }

    h2 {
        margin-top: 3rem;
    }
}