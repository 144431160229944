.loadingContainer {
    height: calc(100vh - 166px); // all vh except navbar and footer
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.svgContainer {
    flex-grow: 1;
    min-height: 100px;
    max-height: 200px;
    aspect-ratio: 1/1;
    min-width: 100px;
}

.svgImage {
   
}

.Sun {
    
}

.allPlanets {
    transform-origin:  41.83006536% 41.83006536%; // 50% - (50/612)*100 %, set by the viewBox
    animation: rotation 4s infinite linear;
}

.firstPlanet {

}

.secondPlanet {

}

.thirdPlanet {

}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading{
  transform: translateX(14px);
}

.waiting{
  text-align: center;
  margin: 1.5rem 0 0.5rem 0;
}