@use './assets/breakpoints';

.logo {
    display: block;
    font-weight: bold;
    text-decoration: none;
    color: var(--white);
    font-size: 1.5rem;
    padding: 1rem 2rem 1rem 2rem;
    background-color: var(--red-1);
    letter-spacing:0.15rem ;
    border-radius: 0 0 1rem 1rem;
}

.header {
 position: sticky;
 top: 0;
 z-index: 1000000000;
 display: flex;
 justify-content: space-between;
 flex-shrink: 0;
 gap: 2rem;
 align-items: center;
 background-color: var(--primary-1);
 height: var(--navbar-height);
}

@media (max-width: breakpoints.$mobile-breakpoint) {
    .header {
        :nth-child(1) {
            order: 2
        }
        :nth-child(2) {
            order: 3
        }
        :nth-child(3) {
            order: 1
        }
    }

    .logo {
        padding: 1.1rem 0.5rem 1.2rem 0.5rem;
        font-size: 1.2rem;
    }
}

@media(min-width: breakpoints.$mobile-breakpoint) {
    .logo {
        margin-left: 2rem;
    }
   
}

