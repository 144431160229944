.nodata {
    padding: 0 1rem 0 1rem;

    h1 {
        margin: 2rem 0 1rem 0;
    }

    p {
        margin: 1rem 0 1rem 0;
    }

    button {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
}