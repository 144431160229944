@use '../assets/breakpoints';

.switch {
    align-self: flex-start;
    display: flex;
    gap: 0.2rem;
}

.switch button {
    font-size: 1rem;
}


@media(min-width: breakpoints.$mobile-breakpoint) {
    .switch {
        margin-right: 2rem;
    }
   
}

@media (max-width: breakpoints.$mobile-breakpoint) {
    .switch {
        flex-direction: column;
        padding: 0;
        margin: 0;
        width: var(--navbar-height);
        height: var(--navbar-height);

        button {
            padding: 0;
            margin: 0;
            width: calc(var(--navbar-height) * 1);
            height: calc(var(--navbar-height) / 3*2);
        }
    }

}