.errorWrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

// inserted div by the Drei library = absolutely positioned div
.errorWrapper > div:first-child {
    width: 100%;
    height: 100%;
}

.error{
    margin: 2rem 3rem 2rem 2rem;
    padding: 1rem 2rem 1rem 2rem;
    display: block;
    color: var(--red-5);
    background-color: var(--red-transparent-2);
    cursor:default;
    user-select: none;
    //min-height: 4rem;
    //width: 100%;
}


