@use 'reset';

@use 'colors';

@use 'breakpoints';

@use 'layout';

@use 'canvasLayout';

* {
    box-sizing: border-box;
}

:root {
    font-family: 'Roboto', sans-serif;
}

body {

}

.desktopMaxWidth {
    max-width: breakpoints.$max-desktop-width;
    margin: 0 auto;
}


.desktopHalfMaxWidth {
    max-width: calc(breakpoints.$max-desktop-width / 2);
    margin: 0 auto;
}

.desktopTwoThirdsMaxWidth {
    max-width: calc(breakpoints.$max-desktop-width * 2 / 3);
    margin: 0 auto;
}


.desktopThirdMaxWidth {
    max-width: calc(breakpoints.$max-desktop-width /3);
    margin: 0 auto;
}


.centerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontalCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gridRow {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}


// hide scrollbar
/*
html::-webkit-scrollbar {
    display: none;
}
// IE and Firefox
html {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
*/

:root {
    font-size: 16px;
}


h1 {
    font-weight: bold;
    font-size: 2rem;
}

h2 {
    font-weight: bold;
    font-size: 1.5rem;
}

h3 {
    font-weight: bold;
    font-size: 1.3rem
}

h4 {
    font-weight: bold;
    font-size: 1.1rem;
}

input {
    display: inline-block;
    box-sizing: border-box;
    border-style: solid;
    border-color: var(--black);
    border-width: 0 0 0.2rem 0;
    border-radius: 0;
    outline: none;
    //padding: 0.2rem;
    //margin: 0.5rem 0 0.5rem 0;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    background-color: var(--grey-transparent-1);
    //text-align: center;
}

button {
    background-color: var(--secondary-1);
    color: var(--black);
    border:0;
    padding:  0.25rem 0.75rem 0.25rem 0.75rem ;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    //margin: 1rem;
    font-size: 1.2rem;
    border-radius: 0rem;
}

button:hover {
    background-color: var(--red-1);
    color: var(--white);
    cursor: pointer;
}


p {
    line-height: 1.6;
}




@keyframes opacityIn {
    0% {
        opacity: 0;
        display: none;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
        display: block;
    }
}


.errorPlaceholder {
    padding: 1rem 2rem 1rem 2rem;
    margin: 0.5rem 0 0.5rem 0;
    opacity: 0;
    display: none;
    color: var(--red-5);
    background-color: var(--red-transparent-2);
    cursor:default;
    user-select: none;
}   

.error {
    opacity: 1;
    display: block;
    //transition: opacity 10s ease-in-out;
    animation: opacityIn 1s;
}


