.link {
    color: var(--white);

    a {
        color: var(--secondary-1);
        font-weight: bold;
    }

    a:visited {
        color: var(--secondary-1);
    }

    a:hover {
        color: var(--red-1) 
    }
}

.white {
    color: var(--white);
}

.whiteBold a {
    color: var(--white);
    font-weight: bold;
    text-underline-offset: 4px;
}
