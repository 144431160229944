.wrapper {
    height: 100%;
    //background-color: grey;
    transition: transform 1s cubic-bezier(0,1,0,1);
    //pointer-events: none;
}

.wrapper input { 
   //text-align: center;
}

.toggleControls {
    height: 100%;
    background-color: var(--grey-transparent-1);
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggleControls:hover {
    background-color: var(--grey-transparent-4);
}

.controls{
    background-color: var(--grey-1);
    width: 100%;
    height: 100%;
}

.arrowContainer {
    width: 1rem;
    height: 1rem;
    margin: 0.5rem;
    //padding: 1rem;
}

.rotateArrow {
    transform: rotate(180deg);
}

.underline {
    border-style: solid;
    border-color: var(--black);
    border-width: 0 0 0.2rem 0;
}


.commonBackground {
    background-color: var(--white);
    margin: 2rem auto 2rem auto;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 1rem;

}

.commonSpacing > div {
   
}

.start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    input {
        max-width: 3.5rem;
        text-align: center;
    }
}

.start > div {
    width: 100%;
}

.timestep {
    input {
        margin: 0 0.5rem 0 0;
        max-width: 50%;
        width: 50%;
    }
}



.chosenCenter {
    max-width: 50%;
        width: 50%;
}

.inactiveCenter {
    max-width: 50%;
        width: 50%;
    background-color: var(--grey-transparent-1);
}

.startstopButton {
    padding: 1rem 2rem 1rem 2rem ;
    margin: 1rem auto 0.5rem auto;
    width: 100%;
}

.startButton {
    background-color: var(--green-1);
}
.startButton:hover {
    background-color: var(--green-2);
    color: var(--black)
}

.stopButton {
    background-color: var(--red-2);
}

.inputLabel {
    font-weight: bold;
}

.objectInfo {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 15rem;
    
}



.newObjectLegend {
    margin-bottom: 0.5rem;
}

.label {
    position: relative;
    bottom: 1rem;
    font-weight: bold;
}

.nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 0.5rem;
}


.dataContainer {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
}

.dataContainer span {
    display: inline-block;
    text-align: end;
    min-width: 4rem;
    padding-right: 0.5rem;
    font-weight: bold;
    border-style: solid;
    border-color: var(--black);
    //border-width: 0 0 0.1rem 0;
}

.dataContainer input {
    width: 4rem;
    text-align: end;
    padding:  0 0.5rem 0 0rem;
}

.dataContainer h4 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}


.buttonsContainer {
    display: flex;
    justify-content: end;
    gap: 0.2rem;
}


.deleteButton {
    background-color: var(--red-2);
    color: var(--black)
}
